import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { configSection, configSectionType, OrderedSectionsConfiguration, GetFullPathTo } from "../../router/routerConfiguration";

export default function MenuButtons(props: any) {
    return (
    <div {...props}>
      {OrderedSectionsConfiguration.map((section: configSection, index: number) => {
        if(section.type === configSectionType.divider){
          return  '|';
        }else{
          return <Link 
          style={{
            fontWeight: 'bold',
            textDecoration: 'none',
            color: 'black',
            cursor: 'pointer'}}
          to={GetFullPathTo(section.title)}>
           <Button 
            className={"pointerOverEffect"}
            tabIndex={index}
            key={index} 
            color="inherit"
            style={{
              fontWeight: 'inherit',
            }}
            >
              {section.title.toUpperCase()}
            </Button>
          </Link>
        }
      })}
    </div>);
}

import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import PrivacyTermsModal from '../common/PrivacyTermsModal';
import RulesModal from "../common/RulesModal";
import { title as RulesTitle } from "../../rules";
import { title as PivacyTermsTitle } from "../../privacy-terms";
import Button from '@material-ui/core/Button';
import sizeMe from 'react-sizeme';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'rgba(0,0,0,0.35)',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  title: {
    flexGrow: 1,
    fontSize: 10,
    color: 'white',
    fontFamily: 'Montserrat'
  },
}));

function Footer()   {
  const [ isModalDisplayed, setIsModalDisplayed ] = useState<boolean>(false);
  const [ isModal2Displayed, setIsModal2Displayed ] = useState<boolean>(false);

  const classes = useStyles();

  return (
      <footer className={classes.root} style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%'}}>
        <div className={classes.title}>
          <span>
            <Button 
              className={"pointerOverEffect"}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                cursor: 'pointer',
                color: 'white',
                textDecoration: 'underline',
                fontSize: "10px",
                textTransform: 'none',
                WebkitTapHighlightColor: 'transparent',
              }}
              onClick={(event:any)=>{
              event.stopPropagation();
              setIsModal2Displayed(true);
            }}>
            {RulesTitle}</Button>
            <>
            Copyright &copy; {new Date().getFullYear()}
            </>
            <Button
              className={"pointerOverEffect"}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                cursor: 'pointer',
                color: 'white',
                textDecoration: 'underline',
                fontSize: "10px",
                textTransform: 'none',
                WebkitTapHighlightColor: 'transparent',
              }}
              onClick={(event:any)=>{
              event.stopPropagation();
              setIsModalDisplayed(true);
            }}>
            {PivacyTermsTitle}</Button>
        </span>
        <PrivacyTermsModal 
          isDisplayed={isModalDisplayed} 
          onHide={()=>{
          setIsModalDisplayed(false);
        }}/>
        <RulesModal
          isDisplayed={isModal2Displayed} 
          onHide={()=>{
          setIsModal2Displayed(false);
        }}/>
        </div>
      </footer>
  )
}

export default sizeMe({ monitorHeight: true, monitorWidth: true })(Footer);
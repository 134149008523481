import { Redirect, Route, Switch } from 'react-router-dom';
import { appBaseRouteKey} from "./routerConfiguration";
import { ProductsScreen, Path as HomePath } from '../components/screens/ProductsScreen';
import { ContactScreen, Path as ContactPath } from "../components/screens/ContactScreen";
import { AboutMeScreen, Path as AboutMePath } from '../components/screens/AboutMeScreen';
import { 
  BuyScreen, 
  Path as BuyPath,
  Subtitle1 as JestemKobietaTitle } from '../components/screens/BuyScreen';
import { GetIdBybCategoryTitle } from "../components/common/BuyItems";
import { CoachingScreen, Title as CoachingTitle, Path as TusPath } from '../components/screens/CoachingScreen';
import { GroupScreen, Title as GroupsTitle, Path as GroupsPath } from '../components/screens/GoupsScreen';

export default function Routes(){
    return(
        <Switch>
            <Route path={appBaseRouteKey + HomePath} exact render={() => <ProductsScreen/>} />
            <Route exact path={appBaseRouteKey + ContactPath} render={() => <ContactScreen/>} />
            <Route exact path={appBaseRouteKey + AboutMePath} render={() => <AboutMeScreen/>} />
            <Route exact path={appBaseRouteKey + BuyPath + "/" + GetIdBybCategoryTitle(JestemKobietaTitle)} render={() => <BuyScreen filterByCategory={JestemKobietaTitle}/>} />
            <Route exact path={appBaseRouteKey + TusPath} render={() => <CoachingScreen filterByCategory={CoachingTitle}/>} />
            <Route exact path={appBaseRouteKey + GroupsPath} render={() => <GroupScreen filterByCategory={GroupsTitle}/>} />
            <Route render={() => <Redirect to={appBaseRouteKey + HomePath} />} />
        </Switch>
    );
}
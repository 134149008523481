import Header from "../molecules/Header";
import Footer from "../molecules/Footer";
import { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { DeviceContextConsumer, DeviceType } from "../../contexts/DeviceContext";

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const MainLayout = (props : any) =>  {
    const [paddingTop, setPaddingTop] = useState<number>(10);
    const [paddingBottom, setPaddingBottom] = useState<number>(10);
    const { innerHeight: height } = window;
    const [mainDivHeight, setMainDivHeight] = useState<number>(height);
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const prevVal = usePrevious(isPortrait);
    const location = useLocation();
    const history = useHistory();

    useEffect(()=>{
      history.replace(location.pathname);
    }, [window.screen.width, window.screen.height, window.innerHeight, window.innerWidth]);

    useEffect(()=>{
      if(prevVal === undefined){
        return;
      }

      if(isPortrait !== prevVal){
        history.replace(location.pathname);
      }
    }, [isPortrait, prevVal]);

    const mainLayoutRef = useRef(null);

    useEffect(() => {
      const resizeListener = () => {
        if (mainLayoutRef.current) {
          setMainDivHeight(window.innerHeight);
        }
      };
      window.addEventListener('resize', resizeListener);
  
      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }, [mainLayoutRef.current]);

    return (
      <DeviceContextConsumer>
        {context =>
        <>
          <Header onSize={(size: any)=>{
              setPaddingTop(size.height || 0);
          }} />
          <main
            ref={mainLayoutRef}
            className="main-layout"
            style={{
              height: mainDivHeight - paddingTop - paddingBottom,
              width: context.valueOf() === DeviceType.isDesktopOrLaptop ? '100%': '100%',
              paddingTop: paddingTop,
              paddingBottom: paddingBottom,
              display: 'inline-flex',
              background: 'radial-gradient(ellipse at bottom, #0B3976 0%, black 100%)',
              justifyContent: 'center',
            }}>
              {props.children}
          </main>
          <Footer onSize={(size: any)=>{
              setPaddingBottom(size.height || 0);
          }}/>
        </>
        }
      </DeviceContextConsumer>
    );
}

export const ContentLayout = (props: any) => {
    return (
      <div style={{
          height: 'inherit',
          width:'inherit',
          display: 'inline-flex',
          alignItems: 'center', 
          justifyContent: 'center'
          }}>
        {props.children}
      </div>
    );
}

export const ContentLayout2 = (props: any) => {
    return (
      <div style={{
        display: 'flex', 
        flexDirection:'column', 
        width:'inherit',
        alignItems: 'stretch', 
        justifyContent: 'stretch',
        backgroundColor: 'white'
        }}>
        {props.children}
      </div>
    );
}
export type ItemDetails = {
    img: Array<string>;
    title: string;
    shortDescription: string;
    price: number;
    priceDescription: string;
    description: string;
    id: string;
    categoryId: string;
    isPhysicalItem: boolean;
    inFuture: boolean;
}

export type CategoryType = {
    id: string;
    title: string;
}

export const GetIdBybCategoryTitle = (title: string): string =>{
    const index = Categories.findIndex((p: CategoryType) => p.title === title);
    if(index > -1){
        return Categories[index].id;
    }else{
        return "";
    }
}

export const Categories: Array<CategoryType> = [
    {
        id: "jestem-kobieta",
        title: "Jestem Kobietą"
    },
    {
        id: "grupy-rozwojowe",
        title: "Warsztaty rozwojowe"
    },
    {
        id: "coaching",
        title: "COACHING"
    }
]

export const tileData: Array<ItemDetails> = [
    {
        inFuture: true,
        img: ['/jk/3m.jpg','/jk/9m.jpg','/jk/14m.jpg','/jk/28m.jpg','/jk/89m.jpg','/jk/jkrm.jpg'],
        title: 'Karty rozwojowe „odkrywam. Jestem Kobietą”',
        shortDescription: 'Zestaw kart „odkrywam. Jestem Kobietą”:\nTalia 90 kart w rozmiarze 10cm x 15cm, o gramaturze 300g, folia matowa, zaokrąglone rogi.\nPrzewodnik w rozmiarze A6 do pracy z kartami z instrukcją, przykładowymi pytaniami do pracy z fotografią, a także cytatami.\nCałość zapakowana w elegancki, welurowy worek. ',
        price: 320,
        priceDescription: "",
        description: "Karty rozwojowe „odkrywam. Jestem Kobietą” powstały z potrzeby wyrażenia siebie i na skutek wielkiej Rewolucji w życiu – udziału w Szkole Odwagi Jesteś Rewolucją <a href='https://szkolaodwagi.pl/' target='_blank'>Magdy Kluszczyk</a>. \n\nCzym są karty „odkrywam. Jestem Kobietą”?\nKarty rozwojowe „odkrywam. Jestem Kobietą” to talia 90 kart. Z wielu setek wspaniałych fotografii wybrałam 90, które w wyjątkowy sposób przedstawiają kobiety.\nChciałabym, żeby karty mogły być dobrym pretekstem do pracy w takich obszarach jak tożsamość, przekonania, ciało, relacje i emocje.\n\nGdzie można wykorzystać karty?\nSkierowane są do coachów, trenerów, terapeutów, a także do wszystkich kobiet pragnących zmiany i pracy nad sobą. ",
        id: "1",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: true,
        img: ['/jk/14m.jpg','/jk/9m.jpg','/jk/3m.jpg','/jk/28m.jpg','/jk/89m.jpg','/jk/jkrm.jpg'],
        title: 'Karty rozwojowe „odkrywam. Jestem Kobietą” - wersja elektroniczna',
        shortDescription: 'Zestaw  90 kart „odkrywam. Jestem Kobietą” w wersji jpg. Przewodnik w rozmiarze A6 do pracy z kartami z instrukcją, przykładowymi pytaniami do pracy z fotografią, a także cytatami w formacie pdf.',
        price: 120,
        priceDescription: "",
        description: "Karty rozwojowe „odkrywam. Jestem Kobietą” powstały z potrzeby wyrażenia siebie i na skutek wielkiej Rewolucji w życiu – udziału w Szkole Odwagi Jesteś Rewolucją <a href='https://szkolaodwagi.pl/' target='_blank'>Magdy Kluszczyk</a>. \n\nCzym są karty „odkrywam. Jestem Kobietą”?\nKarty rozwojowe „odkrywam. Jestem Kobietą” to talia 90 kart. Z wielu setek wspaniałych fotografii wybrałam 90, które w wyjątkowy sposób przedstawiają kobiety.\nChciałabym, żeby karty mogły być dobrym pretekstem do pracy w takich obszarach jak tożsamość, przekonania, ciało, relacje i emocje.\n\nGdzie można wykorzystać karty?\nSkierowane są do coachów, trenerów, terapeutów, a także do wszystkich kobiet pragnących zmiany i pracy nad sobą. ",
        id: "2",
        categoryId: Categories[0].id,
        isPhysicalItem: false
    },
    {
        inFuture: true,
        img: ['/jk/9m.jpg','/jk/3m.jpg','/jk/14m.jpg','/jk/28m.jpg','/jk/89m.jpg','/jk/jkrm.jpg'],
        title: 'Indywidualne spotkanie online z kartami rozwojowymi „odkrywam. Jestem Kobietą”',
        shortDescription: 'Spotkanie 60 – minutowe, online. Termin i zakres tematu do uzgodnienia.',
        price: 100,
        priceDescription: "",
        description: "Zapraszam Cię na spotkanie ze mną.\n\n Jeżeli jesteś coachem, trenerem i chciałabyś zapytać mnie o szczegóły tworzenia, ideę pracy z kartami to zaproszenie jest dla Ciebie. Jeżeli jesteś kobietą, która chce doświadczyć procesu odkrywania, zaglądania w głąb siebie, to zaproszenie jest dla Ciebie. Jeżeli chciałabyś kupić karty, ale nie masz pewności, czy to właściwy wybór, chciałabyś pogadać, spróbować pobyć z kartami w wersji online, to zaproszenie jest dla Ciebie.",
        id: "3",
        categoryId: Categories[0].id,
        isPhysicalItem: false
    },
    {
        inFuture: true,
        img: ['/jk/89m.jpg','/jk/9m.jpg','/jk/3m.jpg','/jk/28m.jpg','/jk/14m.jpg','/jk/jkrm.jpg'],
        title: 'Pakiet: Karty rozwojowe „odkrywam. Jestem Kobietą” + wersja elektroniczna',
        shortDescription: 'Zestaw kart „odkrywam. Jestem Kobietą”:\nTalia 90 kart w formacie jpg oraz w rozmiarze 10cm x 15cm, o gramaturze 300g, folia matowa, zaokrąglone rogi.\nPrzewodnik w rozmiarze A6 do pracy z kartami z instrukcją, przykładowymi pytaniami do pracy z fotografią, a także cytatami.\nCałość zapakowana w elegancki, welurowy worek. ',
        price: 350,
        priceDescription: "",
        description: "Karty rozwojowe „odkrywam. Jestem Kobietą” powstały z potrzeby wyrażenia siebie i na skutek wielkiej Rewolucji w życiu – udziału w Szkole Odwagi Jesteś Rewolucją <a href='https://szkolaodwagi.pl/' target='_blank'>Magdy Kluszczyk</a>. \n\nCzym są karty „odkrywam. Jestem Kobietą”?\nKarty rozwojowe „odkrywam. Jestem Kobietą” to talia 90 kart. Z wielu setek wspaniałych fotografii wybrałam 90, które w wyjątkowy sposób przedstawiają kobiety.\nChciałabym, żeby karty mogły być dobrym pretekstem do pracy w takich obszarach jak tożsamość, przekonania, ciało, relacje i emocje.\n\nGdzie można wykorzystać karty?\nSkierowane są do coachów, trenerów, terapeutów, a także do wszystkich kobiet pragnących zmiany i pracy nad sobą. ",
        id: "4",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: true,
        img: ['/tus/1.jpg', '/tus/2.jpg', '/tus/3.jpg','/tus/4.jpg','/tus/5.jpg'],
        title: 'COACHING',
        description: `
            <b>Oferta Coachingowa – Osiągnij swoje cele z moim wsparciem!</b> to proces partnerskiej współpracy, który pomaga w określaniu celów, pokonywaniu przeszkód i rozwijaniu pełnego potencjału. Dzięki odpowiednim narzędziom i technikom, wspólnie znajdziemy drogę do Twojego sukcesu, zarówno w życiu osobistym, jak i zawodowym.<br/>
            Rodzaje coachingu:
            <ul>
                <li>Coaching Kariery</li>
                <li>Life Coaching</li>
                <li>Coaching Biznesowy</li>
            </ul>`,
        price: 0,
        priceDescription: "Cena pierwszych zajęć wynosi 0zł, każda następna sesja indywidualna to 250zł, pakiet 5 sesji to 1100zł, a pakiet 10 sesji to 2000zł. ",
        shortDescription: `
        <b>Jak wygląda proces coachingowy?</b><ul>
            <li>Konsultacja wstępna (30 minut za darmo): Pierwsze spotkanie online lub na żywo, podczas którego omówimy Twoje potrzeby i oczekiwania. To idealny moment, abyś dowiedział się, jak coaching może Ci pomóc,</li>
            <li>Indywidualne sesje coachingowe: Sesje trwają zazwyczaj 60 minut, a ich ilość zależy od Twoich celów. Możesz wybrać pakiet kilku sesji, aby w pełni skorzystać z procesu coachingowego,</li>
            <li>Praca między sesjami: Otrzymasz zadania i ćwiczenia, które pomogą Ci szybciej osiągnąć zamierzone efekty.,</li>
           </ul>
           Nie czekaj na idealny moment – stwórz go z moją pomocą. Zarezerwuj swoją konsultację wstępną już dzisiaj.
            <br/><b>ZAPRASZAM DO KONTAKTU</b>
        `,
        id: "13",
        categoryId: Categories[2].id,
        isPhysicalItem: false
    },
    {
        inFuture: true,
        img: ['/groups/1.jpg', '/groups/2.jpg', '/groups/3.jpg','/groups/4.jpg'],
        title: 'WARSZTATY ROZWOJOWE DLA KOBIET',
        description: `
            Prowadzę grupy rozwojowe dla kobiet o różnej tematyce (Podróż Bohaterki, Poznaję emocje, Jak pokonać perfekcjonizm i wiele innych).<br/>
            <b>PODRÓŻ BOHATERKI:</b> <br/>to sześciotygodniowy proces, cykl cotygodniowych spotkań, podczas których wyruszę z Tobą w podróż w głąb siebie.<br/>
            <b>TEMATYKA:</b>
            Podczas warsztatowej pracy dowiesz się o wpływie przekonań na Twoje decyzje i działania.<br/>
            Dzięki pracy z fotografią odkryjesz swoje prawdziwe emocje, pragnienia i potrzeby.<br/>
            <b>GRUPA:</b>
            W pięcioosobowej grupie Wspaniałych Kobiet poczujesz moc i siłę, będziesz mogła czerpać energię, a także dzielić się swoją historią.<br/>
            <b>NARZĘDZIA:</b>
            W procesie będziemy pracować przy pomocy fotografii, kart rozwojowych, mapy myśli. Zaproszę Cię także do robienia i wykorzystywania własnych fotografii.<br/>
            <b>POZNAJĘ EMOCJĘ:</b>
            To warsztatowy cykl spotkań, podczas których uczymy się świadomie nazywać i doświadczać emocji. W pracy z emocjami towarzyszy nam fotografia i moje autorskie karty rozwojowe Odkrywam. Jestem Kobietą.<br/>
            <b>JAK POKONAĆ PERFEKCJONIZM:</b>
            To cykl spotkań, podczas których nauczysz się sobie wybaczać, odpuszczać i kochać swoje (nie)doskonałe życie. To także praca  ze wstydem i odwagą.<br/>
            Jeżeli jesteś zainteresowana ofertą warsztatów lub indywidualnych spotkań, zapraszam do kontaktu.`,
        price: 0,
        priceDescription: "",
        shortDescription: `
            Warsztaty prowadzę w grupie 5 – 8 osobowej.
        `,
        id: "144",
        categoryId: Categories[1].id,
        isPhysicalItem: false
    }
 ];
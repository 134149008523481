import React from 'react';
import './App.css';
import './AdditionalStyles.css';
import { DeviceContextProvider } from './contexts/DeviceContext';
import CustomMuiThemeProvider from "./customTheme";
import Routes from "./router/Routes";
import { MainLayout } from './components/layouts/MainLayout';
import { BrowserRouter as Router } from 'react-router-dom';

function ResourceLoadedApp() {
  return (
    <div className="App">
        <CustomMuiThemeProvider>
            <DeviceContextProvider>
              <Router>
                <MainLayout>
                  <Routes/>
                </MainLayout>
              </Router>
            </DeviceContextProvider>
        </CustomMuiThemeProvider>
    </div>
  );
}

export default ResourceLoadedApp;